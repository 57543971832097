import React from "react";
import ListFilter from "./ListFilter";
import RangeFilter from "./RangeFilter";

const t = (filters) => {

  const capitalize = (s) => {
    if (typeof s !== "string") return "";
    return s.charAt(0).toUpperCase() + s.slice(1);
  };

  let tfilters = [];
  Object.keys(filters).forEach((f, i) => {
    let type = "";

    if (
      filters[f].items.length > 0 &&
      (filters[f].items[0]["min"] || filters[f].items[0]["max"])
    ) {
      type = "range";
    }

    if (type === "range") {
      return tfilters.push({
        type: "range",
        label: capitalize(f),
        key: filters[f].key,
        items: filters[f].items,
      });
    } else {
      return tfilters.push({
        type: "list",
        label: capitalize(f),
        key: filters[f].key,
        items: filters[f].items,
      });
    }
  });


  return tfilters;
};


function RenderFilterOptions(props) {
  const {filter, onOptionSelect, value, variant} = props
  if (filter.type === "range") {
    return <RangeFilter variant={variant} value={value} onOptionSelect={onOptionSelect} data={filter.items} />;
  }

  if (filter.type === "list") {
    return <ListFilter variant={variant} value={value} onOptionSelect={onOptionSelect} data={filter.items} />;
  }

  return <React.Fragment></React.Fragment>;
}

export default { t, RenderFilterOptions };
