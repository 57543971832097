const api = require("../../api");
const searchApi = api.search;

const search = (params) => {
  return new Promise((resolve) => {
    searchApi
      .get(params)
      .then((response) => {
        resolve({
          success: response.data ? true : false,
          data: response.data.data,
        });
      })
      .catch((err) => {
        resolve({
          success: false,
        });
      });
  });
};

module.exports =  { search };
