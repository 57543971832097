import React, { useState } from "react";
import { Box, TextField, FormControl, Button } from "@material-ui/core";
import styles from "./RangeFilter.module.scss";

export default function RangeFilter(props) {
  const { value, onOptionSelect } = props;

  // const createRange = (min, max) => {
  //   const diff = 50;

  //   let ranges = [];
  //   for (let i = min; i < max; i += diff) {
  //     let mi = i;
  //     let mx = i + diff;
  //     ranges.push({ min: mi, max: mx, label: `${mi} - ${mx} Rs` });
  //   }

  //   if (max % diff !== 0) {
  //     ranges.push({ min: 0, max, label: `Less than ${max} Rs` });
  //   }

  //   return ranges;
  // };

  // const ranges = createRange(data[0].min, data[0].max);
  const [from, setFrom] = useState(value ? value.from : "");
  const [to, setTo] = useState(value ? value.to : "");

  return (
    <Box style={{ height: "100%" }} className={styles.rangeSet} component="div">
      <FormControl>
        <TextField
          value={from}
          id="outlined-size-small"
          size="medium"
          label={"from"}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          margin="normal"
          onChange={(e) => {
            setFrom(e.target.value);
          }}
        />
      </FormControl>
      <FormControl>
        <TextField
          value={to}
          size="medium"
          label={"to"}
          type="number"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          onChange={(e) => {
            setTo(e.target.value);
          }}
        />
        
      </FormControl>
      <Button
          variant="contained"
          disableElevation
          onClick={(e) => {
            e.preventDefault();
            onOptionSelect({ from, to });
          }}
        >
          Go
        </Button>
    </Box>
  );
}
