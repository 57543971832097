import React from "react";
import { Hidden } from "@material-ui/core";
import MobileFilters from "./MobileFilters";

const FilterMobile = (props) => {
  const { filters, appliedFilters, onSubmit, onReset } = props;

  return (
    <Hidden implementation="css" mdUp>
      <MobileFilters
        selectedFilterBucket={props.selectedFilterBucket}
        appliedFilters={appliedFilters}
        onSubmit={onSubmit}
        filters={filters.filtered || {}}
        allFilter={filters.all || {}}
        onOptionsActive={(active) => props.onOptionsActive(active)}
        onReset={onReset}
      ></MobileFilters>
    </Hidden>
  );
};

export default FilterMobile;
