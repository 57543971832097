import React, { useState } from "react";
import { Button, Box, Paper, Typography } from "@material-ui/core";
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@material-ui/icons";
import helpers from "./helpers.js";
import styles from "./MobileFilters.module.scss";
import clsx from "clsx";

export default function MobileFilters(props) {
  const { appliedFilters, onSubmit, onReset } = props;
  const [showFilterSection, SetShowFilterSection] = useState(false);
  const [selectFilterBtn, SetSelectedFilterBtn] = useState("");
  const [currentFilter, SetCurrentFilter] = useState({});
  const getCurrentFilter = (filters, current) => {
    let filtered = filters.filter((f) => f.label === current);
    if (filtered.length > 0) {
      return filtered.pop();
    }

    return [];
  };

  const appliedCount = (filter) => {
    if (Array.isArray(appliedFilters[filter])) {
      return appliedFilters[filter].length;
    }

    if (appliedFilters[filter] && appliedFilters[filter]["from"]) {
      return 1;
    }

    return 0;
  };

  const appliedCountStr = (count) => {
    if (count < 1) {
      return "";
    }
    return ` (${count})`;
  };

  return (
    <React.Fragment>
      <Paper square elevation={0}>
        <Box
          style={{
            padding: "5px",
            display: "flex",
            alignItems: "center",
            overflowY: "hidden",
            overflow: "auto",
          }}
        >
          {helpers.t(props.filters).map((filter, i) => (
            <Box
              component="span"
              key={filter.key}
              style={{ padding: "3px", whiteSpace: "nowrap" }}
            >
              <Button
                variant="outlined"
                endIcon={
                  selectFilterBtn === filter.label ? (
                    <ArrowDropUpIcon />
                  ) : (
                    <ArrowDropDownIcon />
                  )
                }
                onClick={(e) => {
                  e.preventDefault();
                  let val = filter.label;
                  SetSelectedFilterBtn((btn) => {
                    if (btn === val) {
                      SetShowFilterSection(false);
                      props.onOptionsActive(false);
                      SetCurrentFilter(
                        getCurrentFilter(helpers.t(props.filters), "")
                      );
                      return "";
                    }

                    SetCurrentFilter(
                      getCurrentFilter(helpers.t(props.filters), val)
                    );
                    SetShowFilterSection(true);
                    props.onOptionsActive(true);
                    return val;
                  });
                }}
                className={[
                  clsx({
                    [styles.filterActive]: selectFilterBtn === filter.label,

                    [styles.filterSelection]: appliedCount(filter.key) > 0,
                  }),
                  styles.filterButton,
                ].join(" ")}
              >
                {filter.label + appliedCountStr(appliedCount(filter.key))}
              </Button>
            </Box>
          ))}
        </Box>

        <Box
          className={styles.filterContainer}
          style={{
            display: showFilterSection ? "block" : "none",
          }}
        >
          <Paper
            style={{ width: "100%", height: "100%" }}
            square
            className={styles.filterBox}
          >
            <Box>
              <Typography variant="h6">{selectFilterBtn}</Typography>
              <helpers.RenderFilterOptions
                variant="mobile"
                key={currentFilter.label}
                onOptionSelect={(obj) => {
                  onSubmit(
                    { ...appliedFilters, [currentFilter.key]: obj },
                    currentFilter
                  );
                  props.onOptionsActive(false);
                }}
                filter={{
                  ...currentFilter,
                  items: props.selectedFilterBucket[currentFilter.key]
                    ? props.selectedFilterBucket[currentFilter.key].items
                    : currentFilter.items,
                }}
                value={appliedFilters[currentFilter.key] || null}
              />
              {/* Buttons for Filtering */}
              <Box className={styles.filterButtons}>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    props.onOptionsActive(false);
                    onReset();
                    // onSubmit(selectedFilters);
                  }}
                >
                  Reset Filters
                </Button>
                <Button
                  onClick={(e) => {
                    e.preventDefault();
                    SetCurrentFilter({});
                    SetShowFilterSection(false);
                    SetSelectedFilterBtn("");
                    props.onOptionsActive(false);
                  }}
                >
                  Done
                </Button>
              </Box>
              {/* Buttons for Filtering */}
            </Box>
          </Paper>
        </Box>
      </Paper>
    </React.Fragment>
  );
}
