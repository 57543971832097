import React from "react";
import {
  Box,
  Container,
  Hidden,
  CircularProgress,
  Paper
} from "@material-ui/core";
import Trending from "../../components/Trending/Trending";

export default function Loader(props) {
  const { trendings } = props;

  return (
    <React.Fragment>
      <Box
        bgcolor="#ecf0f2"
        style={{ height: "100%", position: "Absolute", width: "100%" }}
      >
        <Container maxWidth={false} style={{ height: "100%" }}>
          <Hidden implementation="css"  smDown>
            <Trending trendings={trendings} />
          </Hidden>
          <Paper
            style={{
              width: "100%",
              height: "100%",
              paddingTop: "100px",
            }}
          >
            <Box style={{ textAlign: "center", width: "100%", height: "100%" }}>
              <CircularProgress disableShrink />
            </Box>
          </Paper>
        </Container>
      </Box>
    </React.Fragment>
  );
}
