import React from "react";
import { Hidden, Box, Grid, Divider, Button } from "@material-ui/core";
import DesktopFilters from "./DesktopFilters";

const FilterSidebar = (props) => {
  const { filters, appliedFilters, onSubmit, onReset } = props;

  return (
    <Grid container lg={2} md={3} xs={1} direction="row" spacing={0}>
      <Hidden implementation="css" only={["sm", "xs"]}>
        <Grid container item  xs={12} direction="row" spacing={0}>
          <Grid item xs={11}>
            <Box style={{ padding: "10px", paddingTop: "38px" }}>
              <DesktopFilters
                selectedFilterBucket={props.selectedFilterBucket}
                appliedFilters={appliedFilters}
                onSubmit={onSubmit}
                filters={filters.filtered || {}}
                allFilter={filters.all || {}}
                onReset={onReset}
              ></DesktopFilters>
              {/* Button for clearing filters must show or hide if depending on the selection of filters */}
              <Button
                variant="outlined"
                style={{ width: "100%" }}
                onClick={(evt) => {
                  evt.preventDefault();
                  onReset();
                }}
              >
                Reset Filters
              </Button>
            </Box>
          </Grid>
          <Grid item>
            <Box
              style={{
                padding: "21px 0px 21px 0px",
                height: "100%",
              }}
            >
              <Divider orientation="vertical" />
            </Box>
          </Grid>
        </Grid>
      </Hidden>
    </Grid>
  );
};

export default FilterSidebar;
