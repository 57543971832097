import React, { useState } from "react";
import { withWidth } from "@material-ui/core";
import Layout from "../components/Layout";
import SearchPage from "../search";

function Search(props) {
  const { pageContext, location } = props;
  const [query, SetQuery] = useState("");

  return (
    <Layout
      sideMenuCollections={pageContext.sideMenuCollections}
      onQueryChange={(q) => {
        SetQuery(q);
      }}
    >
      <SearchPage
        searcQuery={query}
        trendings={pageContext.trendings}
        fields={pageContext.fields}
        location={location}
      />
    </Layout>
  );
}

export default withWidth()(Search);
