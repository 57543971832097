import React from "react";
import { Box, Typography } from "@material-ui/core";
import helpers from "./helpers.js";
import commonstyle from "../../css/main.module.scss";

export default function DesktopFilters(props) {
  const { appliedFilters, onSubmit } = props;
  // const [currentFilter, SetCurrentFilter] = useState({});

  return (
    <React.Fragment>
      <Box>
        {helpers.t(props.filters).map((filter, i) => (
          <Box key={filter.key} style={{ padding: "0px 0px 15px 0px" }}>
            <Typography variant="subtitle2" className={commonstyle.fwb}>
              {filter.label}
            </Typography>

            <Box style={{ paddingTop: "7px" }}>
              <helpers.RenderFilterOptions
                onOptionSelect={(obj) => {
                  onSubmit(
                    { ...appliedFilters, [filter.key]: obj },
                    filter
                  );
                }}
                filter={{
                  ...filter,
                  items: props.selectedFilterBucket[filter.key]
                    ? props.selectedFilterBucket[filter.key].items
                    : filter.items,
                }}
                value={appliedFilters[filter.key] || null}
              />
            </Box>
          </Box>
        ))}
      </Box>
    </React.Fragment>
  );
}
