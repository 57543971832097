import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Grid,
  MenuItem,
  Select,
  FormControl,
} from "@material-ui/core";
import classes from "./ListingItem.module.scss";
import BootstrapInput from "../../components/Inputs/BootstrapInput";

export default function Actions(props) {
  const { onSortClick, sortBy, resultMeta } = props;
  const [sort, SetSort] = useState(sortBy);

  const { total, page, per_page } = resultMeta;
  const from = per_page * page - per_page + 1;
  let to = from + per_page - 1;
  to = to < total ? to : total;

  useEffect(() => {
    onSortClick(sort);
  }, [sort, onSortClick]);

  return (
    <Box m={2}>
      <Grid container>
        <Grid item xs={4}>
          <Typography style={{ fontSize: "14px", lineHeight: "35px" }}>
            {from} - {to} of {total} Results
          </Typography>
        </Grid>
        <Grid container item xs={8} justify="flex-end">
          <Grid item xs={12}>
            <Box
              style={{ float: "right" }}
              mr={5}
              className={classes.marginR5px}
            >
              <Typography
                variant="overline"
                m={2}
                className={classes.sortbyFix}
              >
                Sort By
              </Typography>
              <FormControl variant="outlined" className={classes.min150Width}>
                <Select
                  value={sortBy}
                  onChange={(e) => SetSort(e.target.value)}
                  input={<BootstrapInput />}
                  onClick={(e) => e.preventDefault()}
                >
                  <MenuItem value={"-released_on"}>Latest</MenuItem>
                  <MenuItem value={"price"}>Lowest Price</MenuItem>
                  <MenuItem value={"-price"}>Highest Price</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
