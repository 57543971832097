import React, { useEffect, useState } from "react";
import {
  Grid,
  Box,
  Container,
  Paper,
  Hidden,
} from "@material-ui/core";
import styles from "../css/main.module.scss";
import Trending from "../components/Trending/Trending";
import ListingItem from "./Listing/ListingItem";
import FilterMobile from "./filters/FilterMobile";
import FilterSidebar from "./filters/FilterSidebar";
import api from "./api/index";
import Actions from "./Listing/Actions";
import { Pagination } from "@material-ui/lab";
import Loader from "./Loader";
import NoResultFound from "./NoResultFound";

function Search(props) {
  const PER_PAGE = 20;
  const DEFAULT_SORT = "-released_on";
  const { fields, trendings } = props;
  const [filters, setFilters] = useState({});
  const [items, setItems] = useState([]);
  const [meta, setMeta] = useState({ page: 1, per_page: PER_PAGE });
  const [appliedFilters, SetAppliedFilters] = useState({
    q: "",
    attributes: {},
    page: 1,
  });
  const [sortBy, SetSortBy] = useState(DEFAULT_SORT);
  const [loading, SetLoading] = useState(false);
  const [selectedFilterBucket, SetSelectedFilterBucket] = useState({});

  const prepareAttributes = (attributes) => {
    let processed = {};
    Object.keys(attributes).forEach((k) => {
      let f = attributes[k];
      let val = "";
      if (Array.isArray(f)) {
        val = f.join("|");
      } else if (f && (f["from"] || f["to"])) {
        val = `${f.from || "0"}_${f.to || "+"}`;
      }
      if (val.trim().length > 0) {
        processed[`attributes[${k}]`] = val;
      }
    });

    return processed;
  };

  useEffect(() => {
    (async (appliedFilters) => {
      let { page, attributes, q } = appliedFilters;

      SetLoading(true);
      const result = await api.search({
        partial: 1,
        sort_by: sortBy,
        page,
        q,
        ...prepareAttributes(attributes),
      });
      SetLoading(false);

      if (result.data) {
        setItems(result.data.records);

        let filters = result.data.meta.filters;
        let fieldsMap = {};
        let all = {};
        let filtered = {};

        Object.values(fields).forEach((el) => {
          if (Array.isArray(el.scopes) && el.scopes.indexOf("filters") > -1) {
            fieldsMap[el.key] = el;
          }
        });
        fieldsMap["price"] = { name: "Price" };

        Object.keys(filters.filtered).forEach((k) => {
          let new_k = k;
          if (k.includes(".")) {
            new_k = k.split(".").pop();
          }

          if (fieldsMap[new_k]) {
            filtered[fieldsMap[new_k].name] = {
              key: k,
              items: filters.filtered[k],
            };
          }
        });

        setFilters({ all, filtered });
        setMeta((meta) => ({
          ...meta,
          page: result.data.meta.page,
          total: result.data.meta.total,
        }));
      }
    })({ ...appliedFilters });
  }, [setItems, setFilters, setMeta, appliedFilters, fields, sortBy]);

  const applyAttributeFilters = (filters) => {
    SetAppliedFilters((f) => {
      let attributes = Object.assign(f.attributes, filters);
      return { ...f, attributes, page: 1 };
    });
  };

  const resetFilters = () => {
    SetSortBy(DEFAULT_SORT);
    SetAppliedFilters((f) => {
      return { ...f, attributes: {}, page: 1 };
    });
  };

  useEffect(() => {
    ((q) => {
      SetAppliedFilters((f) => {
        return { ...f, q, page: 1 };
      });
    })(props.searcQuery);
  }, [props.searcQuery]);

  const handlePageChange = (page) => {
    SetAppliedFilters((f) => {
      return { ...f, page };
    });
  };

  // If search is loading then show loader
  if (loading) {
    return (
      <React.Fragment>
        <Loader
          trendings={trendings}
        />
      </React.Fragment>
    );
  }

  // If no records are found then show no result found image
  if (meta.total === 0) {
    return (
      <React.Fragment>
        <NoResultFound onReset={resetFilters} trendings={trendings} />
      </React.Fragment>
    );
  }

  const t2 = (appliedFilters, currentFilter, _filters) => {
    if (
      currentFilter &&
      _filters[currentFilter.key] &&
      !selectedFilterBucket[currentFilter.key]
    ) {
      console.log(filters.filtered, currentFilter.key);
      let items = [];
      let _a = Object.values(filters.filtered).filter(
        (f) => f.key === currentFilter.key
      );
      if (_a.length > 0) {
        items = _a[0].items;
      }

      if (currentFilter.items.length > items) {
        items = currentFilter.items || [];
      }

      console.log("compare", currentFilter.items, items);
      SetSelectedFilterBucket((b) => ({
        ...b,
        [currentFilter.key]: { items },
      }));

      console.log("selectedFilterBucket", selectedFilterBucket);
    } else {
      // Reset selected filter bucket
      let selectedFilterBucketCopy = Object.assign({}, selectedFilterBucket);
      for (let k in selectedFilterBucketCopy) {
        if (!_filters[k]) {
          delete selectedFilterBucketCopy[k];
        }
      }
      SetSelectedFilterBucket(selectedFilterBucketCopy);
    }

    // let currentSelectedFilters = {}
    // let Object.keys(selectedFilterBucket).filter((k) => appliedFilters[k])
    // SetSelectedFilterBucket((b) => ({
    //   ...b,
    //   ...currentSelectedFilters,
    // }));
  };

  return (
    <React.Fragment>
      <Box bgcolor="#ecf0f2" height="100%">
        <Container maxWidth={false}>
          <Hidden implementation="css" smDown>
            <Trending trendings={trendings} />
          </Hidden>

          <FilterMobile
            selectedFilterBucket={selectedFilterBucket}
            appliedFilters={appliedFilters.attributes}
            onSubmit={(_filters, currentFilter) => {
              t2(appliedFilters, currentFilter, _filters);
              applyAttributeFilters(_filters);
            }}
            filters={filters}
            onOptionsActive={(active) => {
              document.body.style.overflow = active ? "hidden" : "auto";
            }}
            onReset={resetFilters}
          />
          <Paper
            square
            style={{
              marginTop: "3px",
            }}
          >
            <Grid container direction="row" justify="flex-end">
              <FilterSidebar
                selectedFilterBucket={selectedFilterBucket}
                appliedFilters={appliedFilters.attributes}
                onSubmit={(_filters, currentFilter) => {
                  t2(appliedFilters, currentFilter, filters);
                  applyAttributeFilters(_filters);
                }}
                filters={filters}
                onReset={resetFilters}
              />
              <Grid
                container
                item
                lg={10}
                md={9}
                xs={12}
                direction="row"
                alignItems="flex-start"
                style={{ paddingTop: "5px" }}
              >
                <Grid container direction="row" alignItems="flex-start">
                  <Grid item xs={12}>
                    <Actions
                      resultMeta={meta}
                      total={meta.total}
                      sortBy={sortBy}
                      onSortClick={(v) => SetSortBy(v)}
                    />
                  </Grid>
                  <Grid
                    justify="flex-start"
                    alignItems="flex-start"
                    spacing={2}
                    item
                    container
                    xs={12}
                    className="MuiGrid-spacing-xs-2-fix"
                  >
                    {items.map((item, i) => (
                      <Grid item key={i} xl={2} lg={3} md={4} sm={6} xs={12}>
                        <ListingItem
                          url={item.url}
                          price={item.price}
                          image={item.cover_image}
                          title={item.title}
                          release_status={item.release_status || ""}
                        ></ListingItem>
                      </Grid>
                    ))}
                  </Grid>
                  <Grid
                    container
                    item
                    xs={12}
                    alignItems="center"
                    direction="column"
                    className={styles.padded15}
                  >
                    <Pagination
                      count={Math.ceil(meta.total / PER_PAGE)}
                      page={appliedFilters.page}
                      shape="rounded"
                      color="secondary"
                      onClick={(evt) => {
                        evt.preventDefault();
                      }}
                      onChange={(e, val) => {
                        handlePageChange(val);
                        window.scrollTo(5, 5);
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </Box>
    </React.Fragment>
  );
}

export default Search;
