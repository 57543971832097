import React from "react";
import { Box, Container, Button } from "@material-ui/core";

import Image from "./no-result.svg";

export default function NoResultFound(props) {
  const { onReset } = props;
  return (
    <React.Fragment>
      <Box bgcolor="#ecf0f2" height="100%" style={{minHeight:"calc(100vh  - 340px)" }}>
        <Container
          maxWidth={false}
          style={{ textAlign: "center", marginTop: "100px"}}
        >
          {/* <Hidden smDown>
            <Trending trendings={props.trendings} />
          </Hidden> */}
          <img
            src={Image}
            style={{ maxWidth: "200px" }}
            alt="no-result-found"
          />
          <br />
          <br />
          <span>NO RESULT FOUND</span>
          <br />
          <br />
          <Button
            color="primary"
            variant="outlined"
            onClick={(e) => {
              e.preventDefault();
              onReset();
            }}
          >
            Search Again
          </Button>
        </Container>
      </Box>
    </React.Fragment>
  );
}
