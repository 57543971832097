import React from "react";
import { Typography, Box } from "@material-ui/core";
import { Link } from "gatsby";
import { Rating } from "@material-ui/lab";
import Truncate from "react-truncate";
import genClasses from "../../css/main.module.scss";
import styles from "./ListingItem.module.scss";
import { createCdnURL, currencyFormat, appURL } from "../../config/app";
import LazyImage from "../../components/LazyImage";

function renderStatusComingSoon(price, status) {
  if (status === "Coming Soon") {
    return <strong className={styles.comingSoon}>Coming Soon</strong>;
  }

  return (
    <React.Fragment>
      <strong className={styles.arrived}>{currencyFormat(price)}</strong>
    </React.Fragment>
  );
}

function renderStatusDiscontinued(status) {
  if (status === "Discontinued") {
    return <span className={styles.noMore}>Discontinued</span>;
  }

  return <React.Fragment></React.Fragment>;
}

export default function SearchItem(props) {
  const { title, price, url, image, ratings, release_status } = props;

  let thisProdImage = createCdnURL("large", image);
  return (
    <Box className={styles.listing} fontWeight="fontWeightBold">
      <Box className={[genClasses.tac, styles.boxProd].join(" ")}>
        {renderStatusDiscontinued(release_status)}

        <Link
          to={`${appURL}/${url}/`}
         
          
        >
          <LazyImage debounce={100} offset={200} height={300} alt={title} src={thisProdImage} />
        </Link>
      </Box>
      <Link
        to={`${appURL}/${url}/`}
        style={{ textDecoration: "none", color: "#0a182d" }}
      >
        <Truncate lines={2} ellipsis={<span>...</span>}>
          <Typography className={styles.listingTitle}>{title}</Typography>
        </Truncate>
      </Link>
      <Typography className={styles.money}>
        {renderStatusComingSoon(price, release_status)}
      </Typography>
      <Rating size="small" value={ratings || 0} readOnly></Rating>
    </Box>
  );
}
