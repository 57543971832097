import React, { useState } from "react";
import { Typography, Box, FormControlLabel } from "@material-ui/core";
import Checkbox from "./Checkbox/Checkbox";
import styles from "./ListFilter.module.scss";

export default function ListFilter(props) {
  const { data, direction, value, onOptionSelect } = props;
  const [selected, setSelected] = useState(value || []);

  const removeDuplicates = (list) => {
    let uniq = [];
    list.forEach((item) => {
      if (uniq.indexOf(item) < 0) {
        uniq.push(item);
      }
    });

    return uniq;
  };

  const selectVal = (val, active) => {
    let newSelected = [].concat(selected);
    if (active) {
      newSelected = newSelected.concat([val]);
    } else {
      newSelected.splice(newSelected.indexOf(val), 1);
      newSelected = newSelected.concat([]);
    }
    newSelected = removeDuplicates(newSelected);
    setSelected(newSelected);
    onOptionSelect(newSelected);
  };

  return (
    <Box>
      {data
        .filter((el) => el.value && el.value.length > 0)
        .map((el, i) => (
          <Box
            key={i}
            component={direction === "row" ? "span" : "div"}
            className={styles.checkboxSet}
          >
            <FormControlLabel
              control={
                <Checkbox
                  className={
                    selected.indexOf(el.value) > -1 ? "shouldBeSelecte" : ""
                  }
                  checked={selected.indexOf(el.value) > -1}
                  onChange={(evt, active) => {
                    evt.preventDefault();
                    selectVal(el.value, active);
                  }}
                  onClick={(evt) => {
                    evt.preventDefault();
                  }}
                />
              }
              label={
                <Typography
                  variant="body2"
                  component={direction === "row" ? "span" : "p"}
                >
                  {el.value}
                </Typography>
              }
            />
          </Box>
        ))}
    </Box>
  );
}
